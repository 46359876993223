import { render, staticRenderFns } from "./MaterialFolderSelectDialog.vue?vue&type=template&id=25296ae8&scoped=true"
import script from "./MaterialFolderSelectDialog.vue?vue&type=script&lang=ts"
export * from "./MaterialFolderSelectDialog.vue?vue&type=script&lang=ts"
import style0 from "./MaterialFolderSelectDialog.vue?vue&type=style&index=0&id=25296ae8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25296ae8",
  null
  
)

export default component.exports