
import Vue from "vue";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";
import { MaterialFolder } from "@/lib/models";

interface Data {
  checkList: string[];
}

interface Methods {
  checked: (item: MaterialFolder) => boolean;
  onClickOutSide: () => void;
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onCheck: (item: MaterialFolder) => void;
}

interface Props {
  title: string;
  content: string;
  materialId: string;
  items: MaterialFolder[];
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: (ids: string[]) => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon },
  data() {
    return {
      checkList: [],
    };
  },
  props: {
    title: String,
    content: String,
    negative: {
      type: String,
      default: "キャンセル",
    },
    materialId: String,
    items: {
      type: Array,
      default: () => [],
    },
    negativeCallback: Function,
    positive: {
      type: String,
      default: "移動する",
    },
    positiveCallback: Function,
  },
  created() {
    const { items, materialId, checkList } = this;

    for (let index = 0; index < items.length; index += 1) {
      const item = items[index];
      if (item.materialOrder.includes(materialId)) {
        checkList.push(item.id);
      }
    }
  },
  methods: {
    checked(item: MaterialFolder) {
      const { checkList } = this;
      return checkList.includes(item.id);
    },
    onNegativeClick() {
      const { negativeCallback } = this;

      if (negativeCallback) {
        negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      const { positiveCallback, checkList } = this;

      if (positiveCallback) {
        positiveCallback(checkList);
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    onCheck(item: MaterialFolder) {
      const { checkList } = this;

      if (checkList.includes(item.id)) {
        this.checkList = checkList.filter((check) => check !== item.id);
        return;
      }

      checkList.push(item.id);
    },
  },
});

export type MaterialFolderSelectDialogProps = Props;
