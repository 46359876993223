
import Vue from "vue";
import InputText from "@/components/atoms/InputText.vue";

type InputError = {
  error: boolean;
  message: string;
};

interface Data {
  error: InputError;
  value: string;
}

interface Methods {
  onClickOutSide: () => void;
  onNegativeClick: () => void;
  onPositiveClick: () => void;
}

interface Props {
  title: string;
  content: string;
  input: string;
  placeholder?: string;
  limit?: number;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: (value: string) => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { InputText },
  data() {
    return {
      error: {
        error: false,
        message: "",
      },
      value: this.input,
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      const { value } = this;

      if (value.length < 1) {
        this.error = {
          error: true,
          message: "フォルダ名が未入力です。",
        };
        return;
      }

      if (this.positiveCallback) {
        this.positiveCallback(value);
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
  props: {
    title: String,
    content: String,
    input: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 20,
    },
    negative: {
      type: String,
      default: "キャンセル",
    },
    negativeCallback: Function,
    positive: {
      type: String,
      default: "作成する",
    },
    positiveCallback: Function,
  },
});

export type MaterialFolderEditDialogProps = Props;
