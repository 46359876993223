var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.materialAttributeId)?_c('list-template',{attrs:{"active":true}},[_c('div',{staticClass:"list-container",attrs:{"slot":"side"},slot:"side"},[_c('list-header',{staticClass:"list-header",attrs:{"title":_vm.title,"isEditing":_vm.isListEditing || _vm.isDeleteEditing,"showFolderIcon":_vm.showFolderIcon,"updateListState":_vm.updateListState,"updateListType":_vm.updateListType}}),_c('div',{staticClass:"list-items"},[_c('sortable-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFolderIcon),expression:"showFolderIcon"}],attrs:{"items":_vm.reorderableMaterials,"keyAttr":"id","handleClassName":".drag-icon"},on:{"update:items":function($event){_vm.reorderableMaterials=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('material-list-item',{attrs:{"isDeleteEditing":_vm.isDeleteEditing,"isListEditing":_vm.isListEditing,"material":item,"checked":_vm.checkedMaterial(item),"addDeleteMaterial":_vm.addDeleteMaterial,"refresh":_vm.load}})]}}],null,false,96603808)}),_c('folder-list-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFolderIcon),expression:"!showFolderIcon"}],attrs:{"onFolderClick":_vm.selctedFolder,"folder":{
          id: '',
          name: `すべての${_vm.attributeName}`,
          order: [],
          type: 'material',
        }}}),_c('sortable-list',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFolderIcon),expression:"!showFolderIcon"}],attrs:{"items":_vm.reorderableFolders,"keyAttr":"id","handleClassName":".drag-icon"},on:{"update:items":function($event){_vm.reorderableFolders=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('folder-list-item',{attrs:{"isDeleteEditing":_vm.isDeleteEditing,"isListEditing":_vm.isListEditing,"folder":{
            id: item.id,
            name: item.name,
            order: item.materialOrder,
            type: 'material',
          },"checked":_vm.checkedFolder(item),"addDeleteFolder":_vm.addDeleteFolder,"onFolderClick":_vm.selctedFolder,"onInputChanged":_vm.onInputChanged}})]}}],null,false,192847657)})],1),(!_vm.isListEditing && !_vm.isDeleteEditing)?_c('div',{staticClass:"list-bottom label",on:{"click":_vm.onCreateButtonClick}},[_c('img',{staticClass:"white",attrs:{"src":require("@/assets/img/menu-icon/material.png")}}),_vm._v("新規作成する ")]):_vm._e(),(_vm.showUpdateButton)?_c('div',{staticClass:"list-bottom label",on:{"click":_vm.onUpdateButtonClick}},[_c('playlist-edit-icon',{staticClass:"center save",attrs:{"size":28,"fillColor":"#ffffff"}}),_vm._v(_vm._s(_vm.saveText)+" ")],1):_vm._e(),(_vm.showDeleteButton)?_c('div',{staticClass:"list-bottom label delete",on:{"click":_vm.onDeleteButtonClick}},[_c('delete-icon',{staticClass:"center",attrs:{"fillColor":"#ffffff"}}),_vm._v("削除する（"+_vm._s(_vm.deleteCount)+"） ")],1):_vm._e()],1),_c('div',{staticClass:"material-detail",attrs:{"slot":"main"},slot:"main"},[_c('material-detail')],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }