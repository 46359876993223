
import Vue, { PropType } from "vue";
import ListTile from "@/components/molecules/lists/ListTile.vue";
import { NovelMaterial } from "@/lib/models";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";

interface Methods {
  onClick: (event: PointerEvent) => void;
  onCheck: (event: PointerEvent) => void;
  onInput: (event: InputEvent) => void;
}

interface Computed {
  name: string;
  count: number;
  allCount: number;
}

type FolderType = "material" | "character" | "worldview";

type Folder = {
  id: string;
  name: string;
  order: string[];
  type?: FolderType;
};

interface Props {
  isListEditing: boolean;
  isDeleteEditing: boolean;
  checked: boolean;
  handleClassName: string;
  folder: Folder;
  onFolderClick: (val: Folder | null) => void;
  onInputChanged: (folder: Folder) => void;
  addDeleteFolder: (folder: Folder) => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: { ListTile, CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon },
  props: {
    isListEditing: {
      type: Boolean,
      default: false,
    },
    isDeleteEditing: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    handleClassName: {
      type: String,
      default: "handle",
    },
    folder: {
      type: Object as PropType<Folder>,
      default: () => ({
        id: "",
        name: "すべての資料",
        order: [],
      }),
    },
    onFolderClick: {
      type: Function,
      default: (_) => {},
    },
    onInputChanged: Function,
    addDeleteFolder: Function,
  },
  computed: {
    name() {
      const { folder } = this;
      return folder.name;
    },
    count() {
      const { folder, allCount } = this;

      if (folder.id.length < 1) {
        return allCount;
      }

      return folder.order.length;
    },
    allCount() {
      const { folder } = this;
      const { getters } = this.$store;
      const { params } = this.$route;

      switch (folder.type) {
        case "material": {
          const materials = getters["materialModule/materialsFromAttribute"](
            params.materialAttributeId
          ) as NovelMaterial[];
          return materials.length;
        }
        // NOTE: それぞれのタイプ（登場人物、世界観など）のときのカウント取得処理を実装する
        default:
          return folder.order.length;
      }
    },
  },
  methods: {
    onClick(_) {
      const { folder, onFolderClick, isListEditing, isDeleteEditing } = this;

      if (isListEditing || isDeleteEditing) return;

      if (folder.id.length < 1) {
        onFolderClick(null);
        return;
      }

      onFolderClick(folder);
    },
    onCheck(_) {
      const { folder, addDeleteFolder } = this;
      if (addDeleteFolder) {
        addDeleteFolder(folder);
      }
    },
    onInput(event) {
      if (!(event.target instanceof HTMLInputElement)) {
        return;
      }

      const { folder, onInputChanged } = this;
      if (!folder.type) {
        return;
      }

      onInputChanged({
        ...this.folder,
        name: event.target.value,
      });
    },
  },
});
