
import Vue from "vue";
import { API } from "aws-amplify";
import { S3Client } from "@/lib/clients";
import { NolaApiInitializer } from "@/initializer";

interface Data {
  imagePath: string | null;
}

interface Props {
  src: string;
  placeholder: string;
}

export default Vue.extend<Data, unknown, unknown, Props>({
  props: {
    src: String,
    placeholder: {
      type: String,
      default: "/img/placeholders/novel.png",
    },
  },
  data() {
    const { placeholder } = this;
    return {
      imagePath: placeholder,
    };
  },
  async created() {
    const { src } = this;
    if (!src) return;

    let filePath;
    if (src && src.startsWith("preset:")) {
      filePath = `/s3/public/${this.src.substring("preset:".length)}`;
    }

    if (src && src.startsWith("shared")) {
      filePath = `/s3/public/${this.src}`;
    }

    if (src && !filePath) {
      this.imagePath = await new S3Client().getImagePath(src);
      return;
    }

    NolaApiInitializer.init();

    const res = await API.get(
      "Rest",
      filePath, // "/s3/private/ap-northeast-1:09c4c280-71f8-4450-8e6a-4fbf299e1dc9/characters/ac.jpg",
      {}
    );

    this.imagePath = res.url;
  },
});
