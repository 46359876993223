
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import ListTemplate from "@/components/templates/ListTemplate.vue";
import ListHeader from "@/components/molecules/lists/ListHeader.vue";
import MaterialListItem from "@/components/molecules/lists/MaterialListItem.vue";
import FolderListItem from "@/components/molecules/lists/FolderListItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import MaterialDetail from "@/components/organisms/materials/MaterialDetail.vue";
import { ListState, ListType, MaterialAttribute, MaterialFolder, NovelMaterial } from "@/lib/models";
import { Dialog, isBilling } from "@/lib/utils";
import MaterialFolderEditDialog, { MaterialFolderEditDialogProps } from "@/components/ui/MaterialFolderEditDialog.vue";
import PlaylistEditIcon from "icons/PlaylistEdit.vue";
import DeleteIcon from "icons/Delete.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import DialogVue from "@/components/ui/Dialog.vue";

interface Data {
  currentListState: ListState;
  currentListType: ListType;
  reorderableMaterials: NovelMaterial[];
  reorderableFolders: MaterialFolder[];
  selectedMaterials: NovelMaterial[];
  selectedFolders: string[];
  folderId?: string | null;
}

interface Methods {
  initialize: (listType?: ListType, folderId?: string | null) => void;
  load: () => void;
  openInitialMaterial: () => void;
  updateListState: (value: ListState) => void;
  updateListType: (value: ListType) => void;
  selctedFolder: (folder: MaterialFolder) => void;
  onCreateButtonClick: (event?: PointerEvent) => void;
  onUpdateButtonClick: (event: PointerEvent) => void;
  onDeleteButtonClick: (event: PointerEvent) => void;
  onInputChanged: (folder: MaterialFolder) => void;
  setMaterials: () => void;
  addFolder: (value: string) => void;
  addDeleteMaterial: (material: NovelMaterial) => void;
  addDeleteFolder: (folder: MaterialFolder) => void;
  updateMaterials: () => void;
  updateFolders: () => void;
  deleteMaterials: () => void;
  deleteFolders: () => void;
  checkedMaterial: (item: NovelMaterial) => boolean;
  checkedFolder: (item: MaterialFolder) => boolean;
  showErrorDialog: (message: string) => void;
}

interface Computed {
  isListEditing: boolean;
  isDeleteEditing: boolean;
  showFolderIcon: boolean;
  showUpdateButton: boolean;
  showDeleteButton: boolean;
  title: string;
  attributeName: string;
  saveText: string;
  materials: NovelMaterial[];
  folders: MaterialFolder[];
  deleteCount: number;
  recentMaterialId: string | undefined;
}

interface Props {
  novelId: string;
  materialAttributeId: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ListTemplate,
    ListHeader,
    MaterialListItem,
    FolderListItem,
    SortableList,
    MaterialDetail,
    DeleteIcon,
    PlaylistEditIcon,
  },
  data() {
    return {
      currentListState: ListState.COMPLETE,
      currentListType: ListType.ITEM,
      reorderableMaterials: [],
      reorderableFolders: [],
      selectedMaterials: [],
      selectedFolders: [],
      folderId: undefined,
    };
  },
  props: {
    // URL path property
    novelId: String,
    // URL path property
    materialAttributeId: String,
  },
  created() {
    const { initialize } = this;
    initialize();
  },
  methods: {
    initialize(listType = ListType.ITEM, folderId) {
      this.currentListState = ListState.COMPLETE;
      this.currentListType = listType;
      this.load();
      this.selectedMaterials = [];
      this.selectedFolders = [];
      this.folderId = folderId ?? null;
      this.openInitialMaterial();
    },
    load() {
      this.reorderableMaterials = this.materials;
      this.reorderableFolders = this.folders;
    },
    openInitialMaterial() {
      const { $router, $route, reorderableMaterials, recentMaterialId, onCreateButtonClick } = this;

      if (!reorderableMaterials || reorderableMaterials.length === 0) {
        onCreateButtonClick();
        return;
      }

      if (recentMaterialId && $route.params.materialId === recentMaterialId) return;

      let materialId;
      if (recentMaterialId && reorderableMaterials.some((x) => x.id === recentMaterialId))
        materialId = recentMaterialId;
      else materialId = reorderableMaterials[0].id;

      const params = { ...this.$route.params, materialId };
      if ($route.name) {
        $router.push({ name: $route.name, params });
      }
    },
    async addFolder(value: string) {
      const { initialize, novelId, materialAttributeId: attributeId } = this;
      const { dispatch } = this.$store;

      await dispatch("materialModule/createMaterialFolder", {
        novelId,
        attributeId,
        name: value,
      });

      initialize(ListType.FOLDER);
    },
    addDeleteMaterial(material: NovelMaterial) {
      const { selectedMaterials } = this;
      if (selectedMaterials.includes(material)) {
        this.selectedMaterials = selectedMaterials.filter((selectedMaterial) => selectedMaterial.id !== material.id);
        return;
      }

      selectedMaterials.push(material);
    },
    addDeleteFolder({ id }) {
      const { selectedFolders } = this;
      if (selectedFolders.includes(id)) {
        this.selectedFolders = selectedFolders.filter((selectedFolder) => selectedFolder !== id);
        return;
      }

      selectedFolders.push(id);
    },
    async updateMaterials() {
      const { initialize, folderId, materialAttributeId, reorderableMaterials } = this;
      const { getters, dispatch } = this.$store;
      const materialOrder = reorderableMaterials.map((material) => material.id);

      if (folderId) {
        const folder = getters["materialModule/folder"](folderId) as MaterialFolder;
        await dispatch("materialModule/updateMaterialFolder", [
          {
            ...folder,
            materialOrder,
          },
        ]);
        initialize();
        return;
      }

      const attribute = getters["materialModule/attribute"](materialAttributeId) as MaterialAttribute;
      await dispatch("materialModule/updateMaterialAttribute", [
        {
          ...attribute,
          materialOrder,
        },
      ]);
      initialize();
    },
    async updateFolders() {
      const { reorderableFolders } = this;
      for (let index = 0; index < reorderableFolders.length; index += 1) {
        const folder = reorderableFolders[index];
        if (folder.name.length < 1) {
          const { showErrorDialog } = this;
          showErrorDialog("未入力のフォルダが存在します\n※空文字では保存できません");
          return;
        }
      }

      const { dispatch } = this.$store;
      await dispatch("materialModule/updateMaterialFolder", reorderableFolders);

      const { materialAttributeId } = this;
      const { getters } = this.$store;
      const attribute = {
        ...(getters["materialModule/attribute"](materialAttributeId) as MaterialAttribute),
        folderOrder: reorderableFolders.map((folder) => folder.id),
      };
      await dispatch("materialModule/updateMaterialAttribute", [attribute]);

      const { initialize } = this;
      initialize(ListType.FOLDER);
    },
    deleteMaterials() {
      const { initialize, folderId, selectedMaterials } = this;
      const { dispatch, getters } = this.$store;

      if (folderId) {
        const positiveCallback = async () => {
          const deleteIds = selectedMaterials.map((material) => material.id);
          const folder = getters["materialModule/folder"](folderId) as MaterialFolder;
          await dispatch("materialModule/updateMaterialFolder", [
            {
              ...folder,
              materialOrder: folder.materialOrder.filter((id) => !deleteIds.includes(id)),
            },
          ]);
          initialize(ListType.ITEM, folderId);
        };
        const confirmDialog = new Dialog(SimpleDialog);
        const data: SimpleDialogProps = {
          title: "フォルダから資料を削除しますか？",
          content: "本当に資料を削除しますか？\n※再度フォルダに格納することができます",
          positive: "削除する",
          positiveCallback,
        };
        confirmDialog.show(data);
        return;
      }

      const positiveCallback = async () => {
        const deleteIds = selectedMaterials.map((material) => material.id);
        await dispatch("materialModule/deleteNovelMaterial", deleteIds);
        initialize(ListType.ITEM, folderId);
      };

      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "資料を削除しますか？",
        content: "本当に資料を削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback,
      };
      confirmDialog.show(data);
    },
    deleteFolders() {
      const { initialize, selectedFolders } = this;
      const { dispatch } = this.$store;
      const positiveCallback = async () => {
        await dispatch("materialModule/deleteMaterialFolder", selectedFolders);
        initialize(ListType.FOLDER);
      };

      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "フォルダを削除しますか？",
        content: "本当にフォルダを削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback,
      };
      confirmDialog.show(data);
    },
    updateListState(value) {
      this.currentListState = value;

      if (value === ListState.COMPLETE) {
        this.reorderableMaterials = this.materials;
        this.reorderableFolders = this.folders;
        this.selectedMaterials = [];
        this.selectedFolders = [];
      }
    },
    async updateListType(value) {
      const { isListEditing, isDeleteEditing } = this;
      if (isListEditing || isDeleteEditing) {
        return;
      }

      if (value === ListType.FOLDER) {
        const { $store, $router } = this;
        const pushAnnounce = !(await isBilling($store));
        if (pushAnnounce) {
          $router.push({ name: "subscriptionAnnounce", query: { from: "novelMaterialFolder" } });
          return;
        }

        this.reorderableMaterials = [];
      }

      this.currentListType = value;
    },
    selctedFolder(folder: MaterialFolder) {
      const { updateListType, setMaterials } = this;
      updateListType(ListType.ITEM);

      if (folder) {
        this.folderId = folder.id;
        setMaterials();
        return;
      }
      this.folderId = null;
      setMaterials();
    },
    async onCreateButtonClick(_?: PointerEvent) {
      const { $store, $router } = this;
      const pushAnnounce = !(await isBilling($store));
      if (pushAnnounce) {
        $router.push({ name: "subscriptionAnnounce", query: { from: "createNovelMaterial" } });
        return;
      }

      const { folderId, showFolderIcon } = this;
      if (showFolderIcon) {
        const { params } = this.$route;
        this.$router.push({
          name: "materialEdit",
          params: {
            ...params,
            materialId: uuidv4(),
          },
          query: {
            folderId,
          },
        });
        return;
      }

      const { addFolder, attributeName } = this;
      const addAttributeDialog = new Dialog(MaterialFolderEditDialog);
      const data: MaterialFolderEditDialogProps = {
        title: `${attributeName}のフォルダ作成`,
        content: "新しく追加するフォルダ名を入力してください",
        input: "",
        placeholder: "フォルダ名を入力してください（20文字以内）",
        positiveCallback: addFolder,
      };
      addAttributeDialog.show(data);
    },
    onUpdateButtonClick(_: PointerEvent) {
      const { currentListType } = this;
      if (currentListType === ListType.FOLDER) {
        const { updateFolders } = this;
        updateFolders();
        return;
      }

      const { updateMaterials } = this;
      updateMaterials();
    },
    onDeleteButtonClick(_: PointerEvent) {
      const { currentListType } = this;
      if (currentListType === ListType.FOLDER) {
        const { deleteFolders } = this;
        deleteFolders();
        return;
      }

      const { deleteMaterials } = this;
      deleteMaterials();
    },
    onInputChanged({ id, name }) {
      const { reorderableFolders } = this;
      this.reorderableFolders = reorderableFolders.map((reorderableFolder) => {
        if (reorderableFolder.id === id) {
          return {
            ...reorderableFolder,
            name,
          };
        }

        return reorderableFolder;
      });
    },
    setMaterials() {
      this.reorderableMaterials = this.materials;
    },
    checkedMaterial(item) {
      const { selectedMaterials } = this;
      return selectedMaterials.includes(item);
    },
    checkedFolder(item) {
      const { selectedFolders } = this;
      return selectedFolders.includes(item.id);
    },
    showErrorDialog(message: string) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },
  },
  computed: {
    title() {
      const { folderId, attributeName, currentListType } = this;
      const { getters } = this.$store;

      if (currentListType === ListType.FOLDER) {
        return `${attributeName}のフォルダ`;
      }

      if (folderId) {
        const folder = getters["materialModule/folder"](folderId) as MaterialFolder;
        return folder.name ?? "";
      }

      return `すべての${attributeName}`;
    },
    attributeName() {
      const { materialAttributeId } = this;
      const { getters } = this.$store;
      const attribute = getters["materialModule/attribute"](materialAttributeId) as MaterialAttribute;
      if (!attribute) return "資料";
      return attribute.name ?? "資料";
    },
    saveText() {
      const { isListEditing, currentListType } = this;

      if (isListEditing && currentListType === ListType.FOLDER) {
        return "変更を保存する";
      }

      return "並び替えを保存する";
    },
    isListEditing() {
      const { currentListState } = this;
      return currentListState === ListState.EDIT;
    },
    isDeleteEditing() {
      const { currentListState } = this;
      return currentListState === ListState.DELETE;
    },
    showFolderIcon() {
      return this.currentListType === ListType.ITEM;
    },
    showDeleteButton() {
      const { isDeleteEditing, currentListType } = this;
      if (currentListType === ListType.FOLDER) {
        const { selectedFolders } = this;
        return isDeleteEditing && selectedFolders.length > 0;
      }

      if (currentListType === ListType.ITEM) {
        const { selectedMaterials } = this;
        return isDeleteEditing && selectedMaterials.length > 0;
      }

      return false;
    },
    showUpdateButton() {
      const { isListEditing } = this;
      return isListEditing;
    },
    materials() {
      const { getters } = this.$store;
      const { materialAttributeId, folderId } = this;
      return getters["materialModule/materialsFromAttribute"](materialAttributeId, folderId) as NovelMaterial[];
    },
    folders() {
      const { getters } = this.$store;
      const { materialAttributeId } = this;
      const folders = getters["materialModule/foldersFromAttribute"](materialAttributeId) as MaterialFolder[];
      return folders;
    },
    deleteCount() {
      const { currentListType } = this;
      if (currentListType === ListType.FOLDER) {
        const { selectedFolders } = this;
        return selectedFolders.length;
      }

      const { selectedMaterials } = this;
      return selectedMaterials.length;
    },
    recentMaterialId() {
      const { $store, materialAttributeId } = this;
      return $store.getters["materialModule/recentMaterialId"](materialAttributeId);
    },
  },
  watch: {
    materialAttributeId() {
      const { initialize } = this;
      initialize();
    },
  },
});
